<template>
  <label class="toggle">
    <input class="toggle-checkbox" :name="name" type="checkbox" :checked="checkedProp(checked)" @change="isChecked()" />
    <div class="toggle-switch"></div>
    <span class="toggle-label" v-if="label">{{ label }}</span>
  </label>
</template>

<script>
export default {
  data() {
    return {
      value: false,
    };
  },
  props: {
    checked: Boolean,
    label: String,
    name: String,
  },
  methods: {
    isChecked(e) {
      const isChecked = event.srcElement.checked ? true : false;
      this.value = isChecked;
      this.$emit("isChecked", isChecked);
    },
    checkedProp(check) {
      this.value = check;
      if (check) return true;
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
$green: $lipstick;
.toggle {
  cursor: pointer;
  display: inline-block;
}
.toggle-switch {
  display: inline-block;
  background: #ccc;
  border-radius: 16px;
  width: 54px;
  height: 28px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
  &:before,
  &:after {
    content: "";
  }
  &:before {
    display: block;
    background: linear-gradient(to bottom, #fff 0%, #eee 100%);
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
    width: 20px;
    height: 20px;
    position: absolute;
    top: 4px;
    left: 4px;
    transition: left 0.25s;
  }
  .toggle:hover &:before {
    background: linear-gradient(to bottom, #fff 0%, #fff 100%);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
  }
  .toggle-checkbox:checked + & {
    background: $green;
    &:before {
      left: 30px;
    }
  }
}
.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}
.toggle-label {
  margin-left: 5px;
  position: relative;
  top: 2px;
  color: $chicago;
  font-weight: bold;
}
</style>
